
import { Component, Vue } from 'vue-property-decorator'
import { NaviStateModule } from '@/store/navi_state'

@Component({
  components: {}
})
export default class BreadCrumb extends Vue {
  breadcrumbMaxIndex = 3

  get breadcrumbs() {
    const cri = NaviStateModule.currentRouteItem
    // console.log(cri)
    // if (cri) {
    //   SiteStateModule.updatemenutab(cri)
    // }

    const ret = []
    if (cri) {
      let criTemp = cri
      ret.unshift({
        resourceId: criTemp?.name,
        resourceName: criTemp?.name
      })

      while (criTemp?.parent) {
        criTemp = criTemp.parent
        ret.unshift({
          resourceId: criTemp?.name,
          resourceName: criTemp?.name
        })
      }
    }

    return ret
  }
}
