
import { Component, Vue, Watch } from 'vue-property-decorator'
import { NaviStateModule } from '@/store/navi_state'
import HsIconFont from '@/components/hs-iconfont'
import { EVENT_GLOBAL_GOTO_PAGE } from '@/event/event_types'

@Component({
  name: 'SliderMenu',
  components: {
    HsIconFont
  }
})
export default class SliderMenu extends Vue {
  openedKeys: Array<string> = []

  goto(item: NaviNode) {
    console.log('slider menu goto', item)
    this.$eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
      path: item.resourceUrl
    })
  }

  mounted() {
    console.log('mounted SliderMenu')
  }

  renderIcon(iconName?: string | null) {
    if (iconName !== undefined && iconName !== null) {
      return <hs-icon-font type={iconName} />
    }
  }

  renderSideMenu(menus: Array<NaviNode>, isChild?: boolean) {
    return menus.map((m) => {
      if (m.children === undefined || m.children.length === 0) {
        return (
          <a-menu-item onClick={this.goto.bind(this, m)} key={m.name}>
            {isChild ? '' : this.renderIcon(m.icon)}
            <span class={[isChild && 'menu-child-title']}>
              {this.$t(m.name as any)}
            </span>
          </a-menu-item>
        )
      } else {
        return (
          <a-sub-menu key={m.name} popup-class-name="root-menu-popup">
            <span slot="title">
              {this.renderIcon(m.icon)}
              <span>{this.$t(m.name as any)}</span>
            </span>
            {this.renderSideMenu(m.children, true)}
          </a-sub-menu>
        )
      }
    })
  }

  render() {
    if (this.sliderMenus.length > 0) {
      return (
        <div class="slider-menu-wrap">
          <a-menu
            theme="dark"
            openKeys={this.openedKeys}
            onOpenChange={this.onOpenChange}
            selected-keys={this.selectedKeys}
            on={{
              ['update:openKeys']: (e: any) => {
                this.openedKeys = e
              }
            }}
            class={['menu-wrap', 'base-slider-menu'].join(' ')}
            mode="inline"
          >
            {this.renderSideMenu(this.sliderMenus)}
          </a-menu>
        </div>
      )
    }
  }

  onOpenChange(openKeys: string[]) {
    console.log('onOpenChange', openKeys, this.openedKeys)
    //打开一个
    const latestResourceId = openKeys.find(
      (key) => !this.openedKeys.includes(key)
    )
    if (latestResourceId) {
      this.openedKeys = [latestResourceId]
    }
  }

  get selectedKeys(): Array<string> {
    if (!!NaviStateModule.currentRouteItem) {
      return [NaviStateModule.currentRouteItem.name]
    } else {
      return []
    }
  }

  get currentRouteItem() {
    return NaviStateModule.currentRouteItem
  }

  @Watch('currentRouteItem', { immediate: true })
  private selectedKeysChange(
    newItem: NaviNode | null,
    oldItem: NaviNode | null
  ) {
    let currentRouteItem = newItem
    let shouldOpenedKeys = []
    while (currentRouteItem && currentRouteItem.parent) {
      shouldOpenedKeys.push(currentRouteItem.parent.name)
      currentRouteItem = currentRouteItem.parent
    }

    console.log(
      'selectedKeysChange',
      shouldOpenedKeys,
      currentRouteItem,
      oldItem?.name,
      newItem?.name
    )
    if (shouldOpenedKeys.length < 2) {
      this.openedKeys = []
    } else {
      this.openedKeys = shouldOpenedKeys.slice(0, shouldOpenedKeys.length - 1)
    }
  }

  get sliderMenus(): Array<NaviNode> {
    return NaviStateModule.sliderMenus
  }
}
