import { render, staticRenderFns } from "./BreadCrumb.vue?vue&type=template&id=37fe13da&scoped=true&"
import script from "./BreadCrumb.vue?vue&type=script&lang=ts&"
export * from "./BreadCrumb.vue?vue&type=script&lang=ts&"
import style0 from "./BreadCrumb.vue?vue&type=style&index=0&id=37fe13da&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37fe13da",
  null
  
)

export default component.exports